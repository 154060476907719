import { MessageInline } from "@able/react";
import "./legal-disclaimer-component-style.scss";

const LegalDisclaimer = () => {
    return (
        <MessageInline
            description='legal disclaimer about 4G/5G usage'
            variant='Information'
            developmentUrl="/assets/able-sprites.svg"
            className="legal-disclaimer-container"
        >
            <div id="legal-disclaimer" tabIndex="-1" role="region">
                <div className="legal-disclaimer-content">
                    <p>The results displayed show the actual Uplink speed for the service while the device has been using connected to Telstra's network.</p>
                    <p>LANES is designed to provide a minimum target bitrate of 1Mbps in situations of heavy network congestion and will provide higher capacity when possible. The available uplink throughput will vary based on the service signal strength and user load on the network. </p>
                    <p>Preferential data treatment only applies to data carried over the 4G Network for the primary QoS policy.</p>
                    <p>For 5G enabled devices the initial connection will be established through the 4G network and then automatically switch to 5G for data transmission, providing the user benefits of higher data rates and improved performances.</p>
                    <p>Note the above reporting represents speed uplifts delivered on Telstra 4G network and some aspects of our 5G network, meaning the received performance may be faster or slower than what is shown. </p>

                </div>
            </div>
        </MessageInline>
    );
};

export default LegalDisclaimer;