import dayjs from "./dayjs-util";
import { getTimeFormat } from "./date-util";

export const formatNetworkThroughputData = (data, period) => {
    if (typeof data === "object" && data["series"] !== undefined) {
        return data["series"].reduce(
            (prev, curr) => {
                const timestamp = period === 'daily' ? Math.floor((dayjs(curr.timestamp).utc().startOf("day")) / 1000) : Math.floor(dayjs(curr.timestamp) / 1000);
                const value = roundOffThroughputValue(curr.throughput.downLink) || 0;
                prev.categories.push(timestamp);
                prev.values.push({
                    name: getTimeFormat(timestamp, period),
                    x: timestamp,
                    y: Number(value),
                    drilldown: period === "daily" ? true : false,
                });
                return prev;
            },
            { categories: [], values: [] }
        );
    } else {
        return { categories: [], values: [] };
    }
};

export const formatSubscriptionThroughPutData = (data, networkThroughput, period) => {
    if (typeof data === "object" && data["series"] !== undefined) {
        return data["series"].reduce(
            (prev, curr) => {
                const timestamp = period === 'daily' ? Math.floor((dayjs(curr.timestamp).utc().startOf("day")) / 1000) : Math.floor(dayjs(curr.timestamp) / 1000);
                let filteredNetworkdata = [];
                if (typeof networkThroughput === "object" && networkThroughput["series"] !== undefined) {
                    filteredNetworkdata = networkThroughput["series"].filter((sub) => {
                        return sub.timestamp === curr.timestamp;
                    });
                }
                const value =
                    filteredNetworkdata.length > 0
                        ? roundOffThroughputValue(curr.throughput.downLink) -
                          roundOffThroughputValue(filteredNetworkdata[0].throughput.downLink)
                        : roundOffThroughputValue(curr.throughput.downLink);
                prev.categories.push(timestamp);
                prev.values.push({
                    name: getTimeFormat(timestamp, period),
                    x: timestamp,
                    y: Number(value),
                    drilldown: period === "daily" ? true : false,
                });
                return prev;
            },
            { categories: [], values: [] }
        );
    } else {
        return { categories: [], values: [] };
    }
};

export const formatSubscriptionUplinkThroughPutData = (data, period) => {
    if (typeof data === "object" && data["series"] !== undefined) {
        return data["series"].reduce(
            (prev, curr) => {
                const timestamp = period === 'daily' ? Math.floor((dayjs(curr.timestamp).utc().startOf("day")) / 1000) : Math.floor(dayjs(curr.timestamp) / 1000);
                const value = roundOffThroughputValue(curr.throughput.upLink);
                prev.categories.push(timestamp);
                prev.values.push({
                    name: getTimeFormat(timestamp, period),
                    x: timestamp,
                    y: Number(value),
                    drilldown: period === "daily" ? true : false,
                });
                return prev;
            },
            { categories: [], values: [] }
        );
    } else {
        return { categories: [], values: [] };
    }
};

export const roundOffThroughputValue = (data) => {
    let throughPut = data / 1000;
    return throughPut > 5 ? parseInt(throughPut) : throughPut < 1 ? throughPut.toFixed(2) : throughPut.toFixed(1);
};
