import React from "react";
import { ArrowUpIcon } from "../../../../common/components/MaxMinArrows";
import dayjs from "dayjs";
import ThroughputChartNoResults from "../common/throughput-chart-noresults-component";
import { useFetchSubscriptionThroughputHistoryQuery } from "../../../../queries/subscription-throughput-queries";
import { processMonthlyData } from "../../../../utils/process-monthly-data-util";
import "./AverageMonthlyPerformance.scss";

function AverageMonthlyPerformance({ subscriptionId }) {
    //APIs params
    const dateTimeTo = dayjs().tz("Australia/Melbourne").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
    const dateTimeFrom = dayjs()
        .tz("Australia/Melbourne")
        .subtract(1, "day")
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
    const period = "monthly";

    const { networkData, subscriptionData, isLoading, isSuccess } = useFetchSubscriptionThroughputHistoryQuery(
        subscriptionId,
        period,
        dateTimeFrom,
        dateTimeTo
    );

    //Processing and formatting results
    const { networkDownlink, subscriptionDownlink } = processMonthlyData(subscriptionData, networkData);

    //rendering
    return (
        <div className="performance-card">
            {/* When the downlink values are null or an error occurred while fetching data*/}
            {!isLoading && networkDownlink === null && subscriptionDownlink === null && (
                <>
                    <h2 className="performance-card-title"> Average Performance</h2>
                    <ThroughputChartNoResults />
                </>
            )}

            {/* When data is still loading*/}
            {isLoading && !isSuccess && (
                <>
                    <h2 className="performance-card-title"> Average Performance</h2>
                    <div className="performance-with-lanes-value">loading...</div>
                    <div className="performance-without-lanes-value">
                        Last 30 Days with LANES <br />
                        Without LANES: loading...
                    </div>
                </>
            )}

            {/* When data is done loading and the downlink values are available */}
            {!isLoading && isSuccess && networkDownlink !== null && subscriptionDownlink !== null && (
                <>
                    <h2 className="performance-card-title"> Average Performance</h2>
                    <div className="performance-with-lanes-value">
                        <span className="performance-arrow-icon">
                            <ArrowUpIcon developmentUrl="/assets/able-sprites.svg" />
                        </span>
                        {`${subscriptionDownlink}Mbps`}
                    </div>
                    <div className="performance-without-lanes-value">
                        Last 30 Days with LANES <br />
                        Without LANES: {`${networkDownlink}Mbps`}
                    </div>
                </>
            )}
        </div>
    );
}

export default AverageMonthlyPerformance;
