import { useQuery } from "@tanstack/react-query";
import { fetchSubscriptionThroughputHistoryData, fetchNetworkThroughputHistoryData } from "../apis/subscriptions-apis";

export const useFetchSubscriptionThroughputHistoryQuery = (subscriptionId, period, dateTimeFrom, dateTimeTo) => {
    const params = {
        period,
        dateTimeTo,
        dateTimeFrom,
    };

    const {
        data: subData,
        isLoading: subLoading,
        isSuccess: subSuccess,
        error: subError,
    } = useQuery({
        queryKey: ["subscription", subscriptionId, params],
        queryFn: () => fetchSubscriptionThroughputHistoryData(subscriptionId, { params }),
    });

    const {
        data: netData,
        isLoading: netLoading,
        isSuccess: netSuccess,
        error: netError,
    } = useQuery({
        queryKey: ["network", subscriptionId, params],
        queryFn: () => fetchNetworkThroughputHistoryData(subscriptionId, { params }),
    });

    // any error has occurred
    return {
        subscriptionData: subData,
        networkData: netData,
        isLoading: subLoading || netLoading,
        isSuccess: subSuccess && netSuccess,
        error: subError || netError,
    };
}

export const useFetchSubscriptionUplinkThroughputHistoryQuery = (subscriptionId, period, dateTimeFrom, dateTimeTo) => {
    const params = {
        period,
        dateTimeTo,
        dateTimeFrom,
    };

    const {
        data: subData,
        isLoading: subLoading,
        isSuccess: subSuccess,
        error: subError,
    } = useQuery({
        queryKey: ["subscriptionUplink", subscriptionId, params],
        queryFn: () => fetchSubscriptionThroughputHistoryData(subscriptionId, { params }),
    });

    // any error has occurred
    return {
        subscriptionData: subData,
        isLoading: subLoading,
        isSuccess: subSuccess,
        error: subError,
    };
}
